import React from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import {screenshot_1, screenshot_2, screenshot_3, screenshot_4 } from 'images/product/salaris'

class CompleteLowLevel extends React.Component {

  constructor(props) {
    super(props)


    let currentStep = 0
    let currentProgress = 25

    let screenshot = screenshot_1

    this.state = {
      currentImage: screenshot,
      currentStep: currentStep,
      currentProgress: currentProgress
    }
  }

  goToScreenshot(number) {
    const tm = number

    let screenshot = screenshot_1
    let currentStep = 1
    let currentProgress = 25

    switch (true) {
      case (tm == 0):
        screenshot = screenshot_1
        currentStep = 0
        currentProgress = 25
        break;
      case (tm == 1):
        screenshot = screenshot_2
        currentStep = 1
        currentProgress = 50
        break;
      case (tm == 2):
        screenshot = screenshot_3
        currentStep = 2
        currentProgress = 75
        break;
      case (tm == 3):
        screenshot = screenshot_4
        currentStep = 3
        currentProgress = 100
        break;
    }

    this.setState(() => ({
      currentImage : screenshot,
      currentStep: currentStep,
      currentProgress: currentProgress
    }))

  }

  render() {
    const { webApplicatie } = this.props

    return (<section className="complete-low-level padding-l">
      <div className="container-sm">
        <div className="grid center text-center margin-m-bottom">
          <div className="col-12">
            <p className="eyebrow">Speciaal voor het MKB</p>
            <h2>Gebruiksvriendelijke salarisadministratie</h2>
            <p className="streamer center">Employes heeft een moderne interface dat eenvoudig te gebruiken is voor iedereen binnen je bedrijf.</p>
          </div>
        </div>

        <div className="slides">
          <div className="grid text-center space-end margin-xxs-bottom">

            {
              webApplicatie.map(({ icon, title, mess }, idx) => (
              <div className="col-3" key={idx} onClick={e => this.goToScreenshot(idx)}>
                <div className="slide" style={{ opacity: idx === this.state.currentStep ? 1 : 0.7 }}>
                  <div className="slide-title">
                    <img src={icon} alt=""/>
                    <h5>{title}</h5>
                  </div>
                  <p className={`text margin-s-bottom ${idx === this.state.currentStep ? 'active' : ''}`}>{ mess }</p>
                </div>
              </div>))
            }

          </div>

          <div className="grid text-center space-end margin-m-bottom">
              <div className="col-3">
                {this.state.currentStep == 0 && <div className="active-bar"></div>}
                {this.state.currentStep !== 0 && <div className="passive-bar"></div>}
              </div>
              <div className="col-3">
                {this.state.currentStep == 1 && <div className="active-bar"></div>}
                {this.state.currentStep !== 1 && <div className="passive-bar"></div>}
              </div>
              <div className="col-3">
                {this.state.currentStep == 2 && <div className="active-bar"></div>}
                {this.state.currentStep !== 2 && <div className="passive-bar"></div>}
              </div>
              <div className="col-3">
                {this.state.currentStep == 3 && <div className="active-bar"></div>}
                {this.state.currentStep !== 3 && <div className="passive-bar"></div>}
              </div>
          </div>

          <div className="grid text-center center screen video-container">
            <div className="col-12">
              <img src={this.state.currentImage} alt="Illustration" ref="screenshot" />
            </div>
          </div>


        </div>
      </div>
    </section>)
  }
}

export default CompleteLowLevel

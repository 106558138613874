import React from 'react'
import { Link } from 'gatsby'
import { window } from 'browser-monads'
import CompleteLowLevel from 'components/home-complete-low-level'
import CtaRow from 'components/cta-row'
import YouTube from 'react-youtube'
import ClientCard from 'components/client-card'
import * as images from 'images/home'
import {
  avy_1,
  avy_2,
  avy_3,
  avy_5,
  avy_logo,
  woodwatch_1,
  woodwatch_2,
  woodwatch_3,
} from 'images/klanten'
import Layout from '../layouts'
import Overlay from '../components/overlay'

import config from '../config'
const { appUrl, signupRoute } = config

const {
  homeHero,
  employesUI,
  homeCtaIllu,
  homeCtaIlluNew,
  logos: {ixxi, woodwatch, petossi, storageShare, codaisseur},
  illus: { eenvoudig, hrzaken, verlof, koppelingen, bespaar, salaris, hr, declaraties },
  icons: { iconSalaris, iconDeclaraties, iconVerlof, iconHR },
  avatars: { marieke, peter },
} = images

const otherClientStories = [
  {
    name: 'Ilse van Nierop',
    functionTitle: 'HR-manager, Avy',
    companyLogo: avy_logo,
    description:
      'Ik kan zelf de uren invullen, een medewerker aanmaken en wijzigen doorvoeren zonder hiervoor iemand te hoeven inschakelen. Dat is echt heel fijn!',
    portraitImage: avy_5,
    portraitImageSmall: avy_1,
    internallink: true,
    link: '/klanten/avy/',
    btnText: 'Lees ervaring&nbsp;→',
  },
  {
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    companyLogo: woodwatch,
    description: 'Payroll, dat kost me gewoon teveel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→',
  }
]

const webApplicatie = [
  {
    icon: iconSalaris,
    title: 'Salaris',
    mess: 'Binnen enkele minuten kun je de verloning doen.',
  },
  {
    icon: iconHR,
    title: 'HR',
    mess: 'Je hebt overzicht in de personeelsadministratie',
  },
  {
    icon: iconVerlof,
    title: 'Verlof',
    mess: 'Je werknemers kunnen verlof aanvragen.',
  },
  {
    icon: iconDeclaraties,
    title: 'Declaraties',
    mess: 'Voorkom papierwerk en verwerk bonnetjes digitaal',
  },
]

class IndexPage extends React.Component {
  heroVideoOverlay = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      isHeroVideoOpened: false,
      shouldShowHeroVideo: false,
    }
  }

  get heroVideoOptions() {
    return {
      width: '100%',
      height: '100%',
      playerVars: {
        origin: location.origin,
      },
    }
  }

  handleHeroVideoReady = event => {
    this.heroVideo = event.target
    this.heroVideo.playVideo()
  }

  handleHeroVideoShow = () => {
    this.setState({ shouldShowHeroVideo: true })
  }

  handleHeroVideoFinished = () => {
    this.heroVideoOverlay.current.closeOverlay()
  }

  handleHeroVideoClose = () => {
    this.setState({
      isHeroVideoOpened: false,
    })
  }

  handleHeroVideoClosing = () => {
    this.heroVideo.stopVideo()
    this.setState({ shouldShowHeroVideo: false })
  }

  handleVideoPlayButtonClick = () => {
    this.setState({ isHeroVideoOpened: true })
    window.analytics.track('Video played', {
      videoId: 'qIF7KMa7iHM',
    })
  }

  handleVideoCloseButtonClick = () => {
    this.setState({ isHeroVideoOpened: false })
  }

  changeHandler = event => {
    this.setState({
      email: event.target.value,
    })
  }

  submitFormHandler = event => {
    event.preventDefault()
    window.location = `${appUrl}${signupRoute}?email=${this.state.email}`
    window.analytics.track('User entered email on homepage', {
      email: this.state.email,
    })
  }

  render() {
    const { isHeroVideoOpened, shouldShowHeroVideo } = this.state

    return (
      <Layout location={this.props.location}>
        <main className="home animated fadeInPage">
          <header className="header padding-xl">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-7 header-text">
                  <h1>Eenvoudige online salaris&shy;administratie</h1>
                  <p className="streamer margin-m-bottom">
                    Ontdek hoe je met Employes op een makkelijke en moderne manier je Salaris- en HR administratie regelt. Dat bespaart tijd en geeft meer overzicht.
                  </p>

                  <form className="sign-up" onSubmit={this.submitFormHandler}>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                      placeholder="Je zakelijke e-mailadres"
                      className="large combined"
                      required
                    />
                    <button className="btn primary lg combined">
                      Probeer nu
                      <span className="hide-mobile">&nbsp;gratis</span>
                    </button>
                  </form>

                  <br />
                  <p className="small sky-dark">
                    Geheel vrijblijvend{' '}
                    <span className="hide-mobile">
                      | Geen salariskennis nodig{' '}
                    </span>{' '}
                    | Eenvoudig starten
                  </p>
                </div>

                <div
                  className="hero-illustration show-play-button"
                  onClick={this.handleVideoPlayButtonClick}
                >
                  <img src={homeHero} alt="Online salarisadministratie" />
                </div>
              </div>
            </div>

            <div className="logo-grid padding-xl-top hide-mobile block">
              <div className="container-sm margin-xs-bottom">
                <div className="grid yg align-middle logos">


                  {[ixxi, petossi, storageShare, woodwatch].map(
                    (img, idx) => (
                      <div className="col-3 align-middle no-mar" key={idx}>
                        <div className={`logo ${idx === 1 ? 'petossi' : idx === 0 ? 'ixxi' : ''}`}>
                          <img src={img} alt="Klanten logo" />
                        </div>
                      </div>
                    )
                  )}


                </div>
              </div>

              <div className="container-sm">
                <div className="grid center text-center yg align-middle">
                  <p className="hide-mobile">
                    We helpen meer dan 2000 bedrijven en 7500+ werknemers.
                  </p>
                </div>
              </div>

            </div>
          </header>

          <section className="overview padding-xxl">
            <div className="container-md">
              <div className="grid center text-center yg">
                <div className="col-12 margin-xl-bottom">
                  <p className="eyebrow">Waarom</p>
                  <h2>
                    De voordelen van online salarisadministratie via Employes
                  </h2>
                  {/*<p className="streamer center">Helemaal zelf je salarisverwerking doen. Daar heb je geen salariskennis voor nodig. Employes is speciaal gemaakt voor ondernemers.</p>*/}
                  <p className="streamer center">
                    Met Employes heb je de salarisverwerking in eigen hand. Je
                    kunt eenvoudig gegevens invoeren en bekijken. En het doen
                    van een verloning is daarna een fluitje van een cent.
                  </p>
                </div>

                <div className="grid between features">
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={eenvoudig} alt="icoon controle" />
                    </div>
                    <h5>Zelf de controle</h5>
                    <p>
                      Gemakkelijk loonstroken aanmaken, wijzigingen doorvoeren,
                      verlof bijhouden en automatisch arbeidscontracten
                      opstellen, zonder boekhouder als tussenpersoon.
                    </p>
                  </div>
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={bespaar} alt="icoon inzicht" />
                    </div>
                    <h5>Meer inzicht</h5>
                    <p>
                      Beheer het werknemersdossier op één centrale plek en
                      gebruik het voor salaris-, HR- en verlofadministratie. Je
                      kunt ook een koppeling maken met je boekhoudpakket.
                    </p>
                  </div>
                  <div className="col-4 feature">
                    <div className="feature-icon">
                      <img src={hrzaken} alt="icoon support" />
                    </div>
                    <h5>Uitstekende support</h5>
                    <p>
                      Heb je vragen over de salarisverwerking? Wij ondersteunen
                      je snel via online chat, telefoon of e-mail. Voor ons is
                      het belangrijk dat jij optimaal met Employes kunt werken.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <CompleteLowLevel webApplicatie={webApplicatie} />

          <CtaRow xtraSmall freeIllu={homeCtaIlluNew} />

          <section className="possibilities padding-xxl">
            <div className="container-sm">
              <div className="grid center text-center yg">
                <div className="col-12 margin-xl-bottom">
                  <p className="eyebrow">Uitgelichte functionaliteiten</p>
                  <h2 className="no-mar">Ontdek de mogelijkheden</h2>
                </div>
              </div>

              <div className="grid yg">
                <div className="col-6">
                  <Link
                    to="/product/salarisadministratie/"
                    className="possibility-card"
                  >
                    <img
                      className="margin-s-bottom"
                      src={salaris}
                      alt="icoon salaris"
                    />
                    <h4>Salarisadministratie</h4>
                    <p className="margin-m-bottom">
                      Met een paar klikken doe je de maandelijkse
                      salarisverwerking automatisch.
                    </p>
                    <div className="link">
                      Lees meer<span className="arrow right"></span>
                    </div>
                  </Link>
                </div>

                <div className="col-6">
                  <Link to="/product/hr/" className="possibility-card">
                    <img className="margin-s-bottom" src={hr} alt="icoon hr" />
                    <h4>HR</h4>
                    <p className="margin-m-bottom">
                      Stroomlijn het beheer van contracten, verlof- en verzuimregistraties, onboarding en meer.
                    </p>
                    <div className="link">
                      Lees meer<span className="arrow right"></span>
                    </div>
                  </Link>
                </div>

                <div className="col-6">
                  <Link
                    to="/product/verlof/"
                    className="possibility-card no-margin"
                  >
                    <img
                      className="margin-s-bottom"
                      src={verlof}
                      alt="icoon verlof"
                    />
                    <h4>Verlof & verzuim</h4>
                    <p className="margin-m-bottom">
                      Eenvoudig verlof en verzuim bijhouden inclusief
                      automatische verwerking op de loonstroken.
                    </p>
                    <div className="link">
                      Lees meer<span className="arrow right"></span>
                    </div>
                  </Link>
                </div>

                <div className="col-6">
                  <Link
                    to="/product/declaraties/"
                    className="possibility-card no-margin"
                  >
                    <img
                      className="margin-s-bottom"
                      src={declaraties}
                      alt="icoon koppelingen"
                    />
                  <h4>Declaraties</h4>
                    <p className="margin-m-bottom">
                      Voorkom papierwerk en keur declaraties razendsnel goed met Employes.
                    </p>
                    <div className="link">
                      Lees meer<span className="arrow right"></span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="preceded padding-xxl-bottom">
            <div className="container-md">
              <div className="grid yg center text-center padding-xl-bottom">
                <div className="col-12">
                  <p className="eyebrow">Klanten aan het woord</p>
                  <h2>Ervaringen met online salarisadministratie</h2>
                  <p className="streamer center">
                    Onze klanten leggen uit waarom ze Employes voor hun
                    onderneming kiezen en hoe dit in de praktijk uitpakt.
                  </p>
                </div>
              </div>

              <div className="client-cards">
                <div className="grid yg">
                  <ClientCard
                    className="col-8 hide-mobile hide-tablet no-margin"
                    person={otherClientStories[0]}
                    isSmall={false}
                  />
                  <ClientCard
                    className="col-4 hide-mobile hide-tablet"
                    person={otherClientStories[1]}
                    isSmall={true}
                  />
                  <ClientCard
                    className="col-6 extra-small no-pad show-mobile no-margin"
                    person={otherClientStories[1]}
                    isSmall={true}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>

        <Overlay
          showCloseButton
          isOpen={isHeroVideoOpened}
          ref={this.heroVideoOverlay}
          onShow={this.handleHeroVideoShow}
          onClose={this.handleHeroVideoClose}
          onClosing={this.handleHeroVideoClosing}
        >
          {shouldShowHeroVideo && (
            <YouTube
              videoId="qIF7KMa7iHM"
              opts={this.heroVideoOptions}
              containerClassName="hero-video-container"
              onReady={this.handleHeroVideoReady}
              onEnd={this.handleHeroVideoFinished}
            />
          )}
        </Overlay>
      </Layout>
    )
  }
}

export default IndexPage
